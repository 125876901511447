// Here you can add other styles
.custom-select-react {
	#react-select-3-listbox {
		z-index: 9999;
	}
}

.tox-tinymce {
	z-index: 0;
}

.dropzone-container {
	display: flex;
	flex-direction: column;
	font-family: sans-serif;
}

.dropzone-container > p {
	font-size: 1rem;
}

.dropzone-container > em {
	font-size: 0.8rem;
}

.dropzone {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-width: 2px;
	border-radius: 2px;
	border-color: #eeeeee;
	border-style: dashed;
	background-color: #fafafa;
	color: #bdbdbd;
	outline: none;
	transition: border 0.24s ease-in-out;
}

.dropzone:focus {
	border-color: #2196f3;
}

.dropzone.disabled {
	opacity: 0.6;
}

.login-button {
	border: transparent !important;
	background-color: #2f80ed !important;
	width: 100% !important;
}
